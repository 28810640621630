type IterasJsApi = {
  orderingiframe: (options: {
    profile: string
    orderingid: string
    prefill?: object
    next?: string
    cssclass: string
    preauth: string | null
  }) => string
  selfserviceiframe: (options: {
    profile: string
    preauth: string
    cssclass: string
  }) => string
}

declare const window: {
  Iteras: IterasJsApi
} & Window

export const useIteras = () => {
  return useScript<IterasJsApi>(
    {
      src: 'https://app.iteras.dk/static/api/iteras.js',
      crossorigin: false,
    },
    {
      use: () => window.Iteras,
    }
  )
}
// pre-auth token causes a given customer to be logged in automatically in Iteras
// pre-auth token is used in self-service iframe and ordering iframe
export const useIterasPreAuth = async () => {
  const nuxtApp = useNuxtApp()
  const siteConfig = useSiteConfig()
  const userStore = useUserStore()

  return await useAsyncData(
    async () => {
      if (!siteConfig.allowIterasSelfservice || !userStore.user?.userGuid) {
        return null
      }
      const { iterasPreauthToken } =
        await nuxtApp.$api.account.getIterasPreAuth(userStore.user.userGuid)
      return iterasPreauthToken
    },
    { watch: [() => userStore.user?.userGuid] }
  )
}
